const months = [
    {
        name: "January",
        id: "01"
    },
    {   
        name: "February",
        id: "02"
    },
    {   
        name: "March",
        id: "03"
    },
    { 
        name: "April",
        id: "04"
    },
    { 
        name: "May",
        id: "05"
    },
    {
        name: "June",
        id: "06"
    },
    { 
        name: "July",
        id: "07"
    },
    {   
        name: "August",
        id: "08"
    },
    {
        name: "September",
        id: "09"
    },
    {      
        name: "October",
        id: "10"
    }, 
    { 
        name: "November",
        id: "11"
    },
    {
        name: "December",
        id: "12"
    }
]

export default months;