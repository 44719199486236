import React from 'react'

export default function Options(props) {

    return (
        <div className="container--grid">

            <section className="container--intro">
                <div className="intro">
                    <h3>Coming Soon!</h3>
                </div>  
            </section>
        </div>
    )
}